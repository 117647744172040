import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "counter"]

  connect() {
    this.updateCounter(); 
  }

  updateCounter() {
    const maxChars = 280;
    const currentLength = this.textareaTarget.value.length;
      console.log('cheese')
    if (currentLength > maxChars) {
      this.textareaTarget.value = this.textareaTarget.value.substring(0, maxChars);
    }

    this.counterTarget.textContent = `${this.textareaTarget.value.length}/${maxChars}`;
  }
}
