import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["choiceOptionsContainer", "formInputs"];
  STATIC_DRAG_GROUP = 'sortable_container';
  connect() {
    console.log("Connected!")
    this.sortables = [];
    this.initializeSortables();
  }

  disconnect() {
    console.log("Disconnected!")
    this.destroySortables();
  }

  initializeSortables() {
    this.initializeRankSortableContainers();
    this.initializeChoiceOptionsSortableContainer()
  }

  // Creates containers to be dragged to that matches 1 - 4 ranks
  initializeRankSortableContainers(){
    const rankContainers = this.element.querySelectorAll('.shared-container');
    rankContainers.forEach(container => {
      const rankSortableContainer = new Sortable(container, {
        handle: '.handle',
        group: {
          name: container.getAttribute('data-drag-group-value'),
          pull: true,
          put: true,
        },
        animation: 150,
        onEnd: this.onEnd.bind(this),
        onMove: this.onMove.bind(this),
      });
      this.sortables.push(rankSortableContainer);

    });
  }

  // To allow draggable choice options to be placed in container together that the user can drag from and to
  initializeChoiceOptionsSortableContainer(){
    const choiceOptionsContainerSortable = new Sortable(this.choiceOptionsContainerTarget, {
      handle: '.handle',
      group: {
        name: this.choiceOptionsContainerTarget.getAttribute('data-drag-group-value'),
        pull: true,
        put: true,
      },
      animation: 150,
      onEnd: this.onEnd.bind(this),
      onMove: this.onMove.bind(this),
    });
    this.sortables.push(choiceOptionsContainerSortable);

  }

  onMove(event) {
    console.log( this.STATIC_DRAG_GROUP)
    const toContainer = event.to; // The container the card is being dragged over
    const fromContainer = event.from; // The container the card is being dragged from
    const choiceOptionsContainer = this.choiceOptionsContainerTarget; // Access the original container target
    const isDraggedToSortableContainer = toContainer.getAttribute('data-drag-group-value') ===  this.STATIC_DRAG_GROUP
    const isNotDraggedToChoiceOptionsContainer =  toContainer !== choiceOptionsContainer
    const isDraggedFromSortableContainer = fromContainer.getAttribute('data-drag-group-value') === this.STATIC_DRAG_GROUP
    const OriginalPlacementIsNotChoiceOptionsContainer = fromContainer !== choiceOptionsContainer
    // when a choice option is dragged to a rank container, hide other choice options and highlight border
    if (isDraggedToSortableContainer && isNotDraggedToChoiceOptionsContainer) {
      toContainer.classList.add('border-primary-500');
      // hide choice options that are already existing there
      Array.from(toContainer.children).forEach(child => {
        if (child !== event.item) {
          child.style.display = 'none';
        }
      });
    }

    // Remove highlight and show items again if moving away from the container
    if (isDraggedFromSortableContainer && OriginalPlacementIsNotChoiceOptionsContainer) {
      fromContainer.classList.remove('border-primary-500');
      Array.from(fromContainer.children).forEach(child => {
        if (child !== event.item) {
          child.style.display = '';
        }
      });
    }

    // Remove all border-primary-500 from data-drag-group-value divs
    const sharedContainers = document.querySelectorAll(`[data-drag-group-value="sortable_container"]`);
    console.log(sharedContainers, 'sharedContainers')
    sharedContainers.forEach(container => {
      if (container !== toContainer) {
        container.classList.remove('border-primary-500');
        Array.from(container.children).forEach(child => {
          if (child !== event.item) {
            child.style.display = '';
          }
        });
      }
    });

    return true; // Ensure the event continues as expected
  }


  // when choice option is dropped from drag
  onEnd(event) {
    const choiceOptionEL = event.item; // The dragged element
    const toContainer = event.to; // The container it was dragged to
    const fromContainer = event.from; // The container it was dragged from
    const choiceOptionsContainer = this.choiceOptionsContainerTarget; // Access the original container target
    const isDraggedToSortableContainer = toContainer.getAttribute('data-drag-group-value') === this.STATIC_DRAG_GROUP
    const isDraggedToChoiceOptionsContainer = toContainer === choiceOptionsContainer

    // Show all items again when the dragging ends
    Array.from(toContainer.children).forEach(child => {
      child.style.display = '';
    });

    // Adjust rank container when dropped into
    if ( isDraggedToSortableContainer && !isDraggedToChoiceOptionsContainer) {
      // Remove any existing cards in the target container that aren't the newly dragged item
      Array.from(toContainer.children).forEach(child => {
        if (child !== choiceOptionEL) {
          const cardId = child.getAttribute('data-id');
          this.removeHiddenInput(cardId);

          // Remove the "X" button if it exists
          const closeElem = child.querySelector('.close');
          if (closeElem) {
            closeElem.classList.remove('block');
            closeElem.classList.add('hidden');
          }
          const choiceOption = child.querySelector('.choice-option');

          if (choiceOption) {
            choiceOption.classList.remove('md:text-[12px]', 'md:pt-4');
          }

          const img = child.querySelector('img');
          if (img) {
            img.classList.remove('md:h-[78px]', 'md:w-[78px]');
            img.classList.add('h-[40px]', 'w-[40px]');
          }
          // Reset the height
          child.style.height = 'auto';
          choiceOptionsContainer.appendChild(child);

          const handle = child.querySelector('.handle');
          if (handle) {
            handle.classList.remove('hidden');
            handle.classList.add('block');
          }
        }
      });

      const closeElem = choiceOptionEL.querySelector('.close');
      if (closeElem) {
        closeElem.classList.remove('hidden');
        closeElem.classList.add('block');
      }

      const choiceOption = choiceOptionEL.querySelector('.choice-option');
      if (choiceOption) {
        // choiceOption.classList.remove('text-[8px]', 'py-1');
        choiceOption.classList.add('md:text-[12px]', 'md:pt-4');
      }

      const handle = choiceOptionEL.querySelector('.handle');
      if (handle) {
        handle.classList.remove('block');
        handle.classList.add('hidden');
      }

      // Add styling to the card
      // Make image 100px by 100px that exists in itemEl use classes in tailwind
      const img = choiceOptionEL.querySelector('img');
      if (img) {
        img.classList.add('md:h-[78px]', 'md:w-[78px]');
        img.classList.remove('h-[40px]', 'w-[40px]');
      }

      const toContainerPos = toContainer.getAttribute('data-position');
      // Add hidden input to the form
      const cardId = choiceOptionEL.getAttribute('data-id');
      this.addHiddenInput(cardId, toContainerPos);
    }

    // If the card is moved back to the original container
    if (toContainer === choiceOptionsContainer) {
      // Remove the "X" button if it exists
      const removeButton = choiceOptionEL.querySelector('button');
      if (removeButton) {
        removeButton.remove();
      }

      const img = choiceOptionEL.querySelector('img');
      if (img) {
        img.classList.remove('md:h-[78px]', 'md:w-[78px]');
        img.classList.add('h-[40px]', 'w-[40px]');
      }

      const cardId = choiceOptionEL.getAttribute('data-id');
      this.removeHiddenInput(cardId);

    }

    // Ensure the container is no longer highlighted
    fromContainer.classList.remove('border-primary-500');
    toContainer.classList.remove('border-primary-500');
  }


  removeCard(event) {
    event.preventDefault();
    const card = event.target.closest('.card');
    const choiceOptionsContainer = this.choiceOptionsContainerTarget;

    if (choiceOptionsContainer) {
      // Remove the "X" button before moving back
      const removeButton = card.querySelector('button');
      if (removeButton) {
        removeButton.remove();
      }

      const handle = card.querySelector('.handle');
      if (handle) {
        handle.classList.remove('hidden');
        handle.classList.add('block');
      }

      const img = card.querySelector('img');
      if (img) {
        img.classList.remove('md:h-[78px]', 'md:w-[78px]');
        img.classList.add('h-[40px]', 'w-[40px]');
      }

      const closeElem = card.querySelector('.close');
      if (closeElem) {
        closeElem.classList.add('hidden');
        closeElem.classList.remove('block');
      }

      const choiceOption = card.querySelector('.choice-option');
      if (choiceOption) {
        choiceOption.classList.remove('md:text-[12px]', 'md:pt-4');
        // choiceOption.classList.add('text-[8px]', 'py-1');
      }

      // Move the card back to the original container
      choiceOptionsContainer.appendChild(card);

      // Remove hidden input from the form
      const cardId = card.getAttribute('data-id');
      this.removeHiddenInput(cardId);

      // Remove the red border from the parent container
      card.parentElement.classList.remove('border-primary-500');
    }
  }

  // Form input methods
  addHiddenInput(cardId, position) {
    const formInputs = this.formInputsTarget;
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'selected_ids[]';
    input.value = JSON.stringify({ id: cardId, position: position });
    input.setAttribute('data-card-id', cardId);
    formInputs.appendChild(input);
  }

  removeHiddenInput(cardId) {
    const formInputs = this.formInputsTarget;
    const input = formInputs.querySelector(`input[data-card-id="${cardId}"]`);
    if (input) {
      formInputs.removeChild(input);
    }
  }
  destroySortables() {
    this.sortables.forEach(sortable => {
      console.log("Destroying sortable!")
      sortable.destroy();
    });
    this.sortables = [];
  }
}
